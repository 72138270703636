.process-step-button {
    width: 100%;
    position: fixed;
   // bottom: 0;
   bottom: 0;
    left: 0;
    right: 0;
  //  padding: 15px 0px;
  //  padding: 15px 0 25px 0;
    padding: 15px 0px 45px 0;
    background-color: #FFFFFF;
    z-index: 1;
    margin: auto;
    box-shadow: 0 -3px 8px rgb(0 0 0 / 8%);

    &__button-wrap {
      width: calc(100% - 30px);
      display: flex;
      justify-content: space-between;
      margin: auto;

      display: flex;
      align-items: center;
      justify-content: center;

    }

    &__button {
        min-width: 100px;
        padding: 12px 24px;
        font-size: 14px;
        font-weight: 400;
        border-radius: 22px;
        transition: 0.2s;

        &:focus-visible {
            outline: none;
        }
        color: #ffffff;
        border: 1px solid #F68B1F;
        background: #F68B1F;
        &:hover {
            color:  #F68B1F;
            background-color: #ffffff;
        }


      &--back {
        display: flex;
        align-items: center;
        padding-left: 0;
        white-space: nowrap;
        background-color: #FFFFFF;
        color: #494949;
        border: 1px solid transparent;

        &:hover {
          color: #494949;
        }
      }

      &--next {
        width: 50%;
        min-width: 154px;
        height: 44px;
        font-size: 14px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        img {
          height: 16px;
          margin-left: 5px;
          object-fit: contain;
          object-position: center;
        }

      }

      &--cancel {
        width: 50%;
        min-width: 154px;
        height: 44px;
        border: 1px solid #00000033;
        color: #363533CC;
        background: transparent !important;
        text-align: center;

      }

      &--cancel:hover {
        color:#36353366;
        background-color: #F9F9F9;
        border-color: #36353333;
      }
    }

    &__icon {
      margin-right: 10px;
    }
  }
